import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <div>
      <img src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExajI5cjdwOTNkcWpmdDQ5eWI0cnE1MTVweWxicGpzNGMxMGMwdXlxdiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/T2vDaYr8yRhrpFe6WE/giphy.gif"></img>
      <i style={{
        display: 'block'
      }}>It's GONE MacReady</i>
    </div>
    <br/>
    <br/>
  </Layout>
)

export default NotFoundPage
